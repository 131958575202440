import React from "react";
import "./stylesheet/SuccessModal.css";

const SuccessModal = ({ onClose }) => (
  <div className="modal-overlay" onClick={onClose}>
    <div className="modal" onClick={(e) => e.stopPropagation()}>
      <h2>Message sent successfully!</h2>
      <button onClick={onClose}>Close</button>
    </div>
  </div>
);

export default SuccessModal;
