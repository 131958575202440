import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./stylesheet/Contact.css";
import LoadingSpinner from "./LoadingSpinner"
import SuccessModal from "./SuccessModal";
import { useTranslation } from "react-i18next";


function ContactUs() {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState(""); // New state for email
  const [messageText, setMessageText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const templateParams = {
      name: name,
      company: company,
      message: messageText,
      email: email, // Add email to the template parameters
    };

    emailjs
      .send(
        "service_swtbpje",
        "template_9i8uh3k", // Replace with your actual template ID
        templateParams,
        "kWarCXC2BaoUcWQbc" // Replace with your actual user ID
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsLoading(false);
          setIsSuccessModalOpen(true);
          setName("");
          setCompany("");
          setMessageText("");
          setEmail(""); // Reset email field
        },
        (error) => {
          console.log(error.text);
          setIsLoading(false);
          alert("Failed to send message. Please try again later.");
        }
      );
  };

  const closeModal = () => {
    setIsSuccessModalOpen(false);
  };

  return (
    <div className="contact-us">
      {isLoading && <LoadingSpinner />}
      {isSuccessModalOpen && <SuccessModal onClose={closeModal} />}
      <h1>{t("contact.title")}</h1>
      <form className="contact-form" onSubmit={sendEmail}>
        <label htmlFor="name">{t("contact.name")}</label>
        <input
          type="text"
          name="name"
          placeholder={t("contact.name_ph")}
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        <label htmlFor="company">{t("contact.company")}</label>
        <input
          type="text"
          name="company"
          placeholder={t("contact.company_ph")}
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          required
        />

        <label htmlFor="email">{t("contact.email")}</label> {/* New email field */}
        <input
          type="email"
          name="email"
          placeholder={t("contact.email_ph")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <label htmlFor="message">{t("contact.message")}</label>
        <textarea
          name="message"
          placeholder={t("contact.message_ph")}
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          required
        ></textarea>
        <input type="submit" value={t("contact.button")} />
      </form>
    </div>
  );
}

export default ContactUs;
