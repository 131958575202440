import React, {useState, useEffect } from "react";
import "./stylesheet/MachineProduction.css";
import img1 from "../assets/cbbanner2.jpeg";
import img2 from "../assets/shredder.jpeg";
import img3 from "../assets/rolling-mill-transparent.png";
import img4 from "../assets/r1.jpeg";
import { useTranslation } from "react-i18next";
import { Routes, Route, useNavigate } from "react-router-dom";
import Machine1 from './Machine1';
import Machine2 from './Machine2';
import Machine3 from './Machine3';
import Machine4 from './Machine4';
import Modal from "react-modal";

Modal.setAppElement('#root'); // This line is important for accessibility reasons.


function MachineProduction() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [modal1IsOpen, setModal1IsOpen] = useState(false);
  const [modal2IsOpen, setModal2IsOpen] = useState(false);
  const [modal3IsOpen, setModal3IsOpen] = useState(false);
  const [modal4IsOpen, setModal4IsOpen] = useState(false);

  const modalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)', // This will make the background dark.
      zIndex: 1000, // This ensures the modal is above everything else.
    },
    content: {
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      background: '#121212', // Main color
      color: 'ghostwhite', // Secondary color
      border: 'none', // Removes the default border
      borderRadius: '0', // Removes the default border radius
    },
  };

  const closeBtnStyles = {
    position: 'fixed',
    top: '2em',
    right: '2em',
    background: "#e63946", // Secondary color
    border: 'none',
    borderRadius: '50%', // Make the button circular
    color: "#FFFFFF", // Color for 'X'
    fontSize: '1em',
    cursor: 'pointer',
    height: '1.5em', // Define the size of the button
    width: '1.5em', // Define the size of the button
    display: 'flex', // To center 'X' vertically and horizontally
    alignItems: 'center', // To center 'X' vertically
    justifyContent: 'center', // To center 'X' horizontally
  };


  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    if (modal1IsOpen || modal2IsOpen || modal3IsOpen || modal4IsOpen) {
      setScrollPos(window.pageYOffset);
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPos}px`;
    } else {
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, scrollPos);
    }
  }, [modal1IsOpen, modal2IsOpen, modal3IsOpen, modal4IsOpen]);



  return (
    <div className="machine-production">
      <div className="title">
        <div className="line"></div>
        <h1>{t("machine_production.title")}</h1>
      </div>
      <div className="content-container">
        <div>
        <h2>{t("machine_production.heading_1")}</h2>
        <Modal isOpen={modal1IsOpen} onRequestClose={() => setModal1IsOpen(false)} contentLabel="Machine 1 Modal" style={modalStyles}>
            <Machine1 />
            <button onClick={() => setModal1IsOpen(false)} style={closeBtnStyles}>X</button>
          </Modal>
        <div onClick={() => setModal1IsOpen(true)} className="mobile-container">
            <img src={img1} alt="titanium-conveyor-belts"/>
          </div>
        </div>
        <div>
        <h2 onClick={() => setModal2IsOpen(true)}>{t("machine_production.heading_2")}</h2>
        <Modal isOpen={modal2IsOpen} onRequestClose={() => setModal2IsOpen(false)} contentLabel="Machine 2 Modal" style={modalStyles}>
            <Machine2 />
            <button onClick={() => setModal2IsOpen(false)} style={closeBtnStyles}>X</button>
          </Modal>
          <div onClick={() => setModal2IsOpen(true)}  className="mobile-container">
            <img src={img2} alt="titanium-shredder"/>
          </div>
        </div>
        <div>
        <h2 onClick={() => setModal3IsOpen(true)}>{t("machine_production.heading_3")}</h2>
        <Modal isOpen={modal3IsOpen} onRequestClose={() => setModal3IsOpen(false)} contentLabel="Machine 3 Modal" style={modalStyles}>
            <Machine3 />
            <button onClick={() => setModal3IsOpen(false)} style={closeBtnStyles}>X</button>
          </Modal>
        <div onClick={() => setModal3IsOpen(true)}  className="mobile-container">
          <img src={img3} alt="titanium-mill"/>
          </div>
        </div>
        <div>
        <h2 onClick={() => setModal4IsOpen(true)}>{t("machine_production.heading_4")}</h2>
        <Modal isOpen={modal4IsOpen} onRequestClose={() => setModal4IsOpen(false)} contentLabel="Machine 4 Modal" style={modalStyles}>
            <Machine4 />
            <button onClick={() => setModal4IsOpen(false)} style={closeBtnStyles}>X</button>
          </Modal>
          <div onClick={() => setModal4IsOpen(true)}  className="mobile-container">
          <img src={img4} alt="titanium-ewaste"/>
          </div>
        </div>
      </div>

    </div>
  );
}

export default MachineProduction;
