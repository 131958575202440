import React from "react";
import "./stylesheet/AboutUs.css";
import img1 from "../assets/rawmaterialproduction.jpg";
import img2 from "../assets/metalprocessing.jpg";
import img3 from "../assets/rnd.jpg";
import img4 from "../assets/machineproduction.jpg";
import img5 from "../assets/metallurgy.png";
import { useTranslation } from "react-i18next";

function AboutUs() {
  const { t } = useTranslation();

  return (
    <div className="about-us">
      <div className="title">
        <div className="line"></div>
        <h1>{t("about_us.title")}</h1>
      </div>
      <div className="content-container">
        {/* <div className="grid-item">
          <img src={img1} alt="grid-item-1" />
          <div className="item-details">
            <h2>{t("about_us.raw_material_production.title")}</h2>
            <p>{t("about_us.raw_material_production.description")}</p>
            <button class="cta">
              <svg
                viewBox="0 0 46 16"
                height="10"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
                id="arrow-horizontal"
              >
                <path
                  transform="translate(30)"
                  d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                  data-name="Path 10"
                  id="Path_10"
                  fill="#e63946"
                ></path>
              </svg>
            </button>
          </div>
        </div> */}
        <div className="grid-item">
          <img src={img2} alt="grid-item-2" />
          <div className="item-details">
            <h2>{t("about_us.metal_processing.title")}</h2>
            <p>{t("about_us.metal_processing.description")}</p>
            <button class="cta">
              <svg
                viewBox="0 0 46 16"
                height="10"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
                id="arrow-horizontal"
              >
                <path
                  transform="translate(30)"
                  d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                  data-name="Path 10"
                  id="Path_10"
                  fill="#e63946"
                ></path>
              </svg>
              {/* <span class="hover-underline-animation">
                {t("about_us.read_more")}
              </span> */}
            </button>
          </div>
        </div>
        <div className="grid-item">
          <img src={img3} alt="grid-item-3" />
          <div className="item-details">
            <h2>{t("about_us.research_and_development.title")}</h2>
            <p>{t("about_us.research_and_development.description")}</p>
            <button class="cta">
              <svg
                viewBox="0 0 46 16"
                height="10"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
                id="arrow-horizontal"
              >
                <path
                  transform="translate(30)"
                  d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                  data-name="Path 10"
                  id="Path_10"
                  fill="#e63946"
                ></path>
              </svg>
              {/* <span class="hover-underline-animation">
                {t("about_us.read_more")}
              </span> */}
            </button>
          </div>
        </div>
        <div className="grid-item">
          <img src={img4} alt="grid-item-4" />
          <div className="item-details">
            <h2>{t("about_us.machine_production.title")}</h2>
            <p>{t("about_us.machine_production.description")}</p>
            <button class="cta">
              <svg
                viewBox="0 0 46 16"
                height="10"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
                id="arrow-horizontal"
              >
                <path
                  transform="translate(30)"
                  d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                  data-name="Path 10"
                  id="Path_10"
                  fill="#e63946"
                ></path>
              </svg>
              {/* <span class="hover-underline-animation">
                {t("about_us.read_more")}
              </span> */}
            </button>
          </div>
        </div>
        <div className="grid-item">
          <img src={img5} alt="grid-item-5" />
          <div className="item-details">
            <h2>{t("about_us.metallurgy.title")}</h2>
            <p>{t("about_us.metallurgy.description")}</p>
            <button class="cta">
              <svg
                viewBox="0 0 46 16"
                height="10"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
                id="arrow-horizontal"
              >
                <path
                  transform="translate(30)"
                  d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                  data-name="Path 10"
                  id="Path_10"
                  fill="#e63946"
                ></path>
              </svg>
              {/* <span class="hover-underline-animation">
                {t("about_us.read_more")}
              </span> */}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
