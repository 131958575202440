import React from "react";
import { useTranslation } from "react-i18next";
import banner from "../assets/shredder.jpeg";
import "./stylesheet/Machine2.css";

function Machine2() {
  const { t } = useTranslation();

  return (
    <div className="banner-container">
      <img src={banner} alt="Banner" className="banner" />
      <h1 className="banner-title">{t("machine_production.heading_2")}</h1>

      <div className="content-container">
        <div className="content-item s1">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h2_1")}</h3>
          <p>{t("machine_production.modal.d2_1")}</p>

        <div className="content-item s2">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h2_2")}</h3>
          <p>{t("machine_production.modal.d2_2")}</p>

        <div className="content-item s3">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h2_3")}</h3>
          <p>{t("machine_production.modal.d2_3")}</p>

        <div className="content-item s4">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h2_4")}</h3>
          <p>{t("machine_production.modal.d2_4")}</p>

        <div className="content-item s5">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h2_5")}</h3>
          <p>{t("machine_production.modal.d2_5")}</p>

        <div className="content-item s6">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h2_6")}</h3>
          <p>{t("machine_production.modal.d2_6")}</p>
      </div>
    </div>
  );
}

export default Machine2;
