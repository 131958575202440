import React from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useLayoutEffect, useRef, useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import RawMaterialProduction from './components/RawMaterialProduction';
import MetalProcessing from './components/MetalProcessing';
import ResearchAndDevelopment from './components/ResearchAndDevelopment';
import MachineProduction from './components/MachineProduction';
import Metallurgy from './components/Metallurgy';
import ContactUs from './components/ContactUs';
import { BrowserRouter as Router } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import LoadingSpinner from './components/LoadingSpinner';



gsap.registerPlugin(ScrollTrigger);



function App() {
  const component = useRef();
  const [loading, setLoading] = useState(true); // add state for loadingSpinner

  const handleButtonClick = () => {
    aboutUsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const aboutUsRef = useRef(null);

  useEffect(() => {
    const handleLoad = () => {
      setLoading(false);
    };

    if (document.readyState === "complete") {
      handleLoad();
    } else {
      document.addEventListener("readystatechange", () => {
        if (document.readyState === "complete") {
          handleLoad();
        }
      });
    }

    return () => {
      document.removeEventListener("readystatechange", handleLoad);
    };
  }, []);




  return (
    <>
          {loading && <LoadingSpinner />} {/* render loadingSpinner while loading */}

    <Router>
     <div className="App" ref={component}>
        <Header/>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#000" }}
          buttonStyle={{ color: "#fff", fontSize: "16px", background: "#f00" }}
          expires={150}
        >
          We use cookies to enhance your experience. By continuing to visit this site, you agree to our use of cookies.
        </CookieConsent>
        <div className="firstContainer">
        <Home onButtonClick={handleButtonClick}/>
        </div>
        <div className="panel" id="aboutUs" ref={aboutUsRef}>
  <div>
    <AboutUs/>
  </div>
</div>
          <div className="panel" id="machineProduction">
            <div>
              <MachineProduction/>
            </div>
          </div>
          {/* <div className="panel" id="rawMaterialProduction">
            <div>
              <RawMaterialProduction/>
            </div>
          </div> */}
          <div className="panel" id="metallurgy">
            <div>
              <Metallurgy/>
            </div>
          </div>
          {/* <div className="panel">Four</div> */}
        <div className="lastContainer">
          <ContactUs/>
        </div>
      </div>
    </Router>
    </>
  );
}

export default App;
