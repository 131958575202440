import React from "react";
import { useTranslation } from "react-i18next";
import banner from "../assets/r2.jpeg";
import "./stylesheet/Machine4.css";

function Machine4() {
  const { t } = useTranslation();

  return (
    <div className="banner-container">
      <img src={banner} alt="Banner" className="banner" />
      <h1 className="banner-title">{t("machine_production.heading_4")}</h1>

      <div className="content-container">
        <div className="content-item r1">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h4_1")}</h3>
          <p>{t("machine_production.modal.d4_1")}</p>

        <div className="content-item r2">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h4_2")}</h3>
          <p>{t("machine_production.modal.d4_2")}</p>

        <div className="content-item r3">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h4_3")}</h3>
          <p>{t("machine_production.modal.d4_3")}</p>

        <div className="content-item r4">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h4_4")}</h3>
          <p>{t("machine_production.modal.d4_4")}</p>

        <div className="content-item r5">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h4_5")}</h3>
          <p>{t("machine_production.modal.d4_5")}</p>

        <div className="content-item r6">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h4_6")}</h3>
          <p>{t("machine_production.modal.d4_6")}</p>
      </div>
    </div>
  );
}

export default Machine4;
