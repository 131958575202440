import React from "react";
import { useTranslation } from "react-i18next";
import banner from "../assets/cbBanner.jpeg";
import "./stylesheet/Machine1.css";

function Machine1() {
  const { t } = useTranslation();

  return (
    <div className="banner-container">
      <img src={banner} alt="Banner" className="banner" />
      <h1 className="banner-title">{t("machine_production.heading_1")}</h1>

      <div className="content-container">
        <div className="content-item cb1">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h1_1")}</h3>
          <p>{t("machine_production.modal.d1_1")}</p>

        <div className="content-item cb2">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h1_2")}</h3>
          <p>{t("machine_production.modal.d1_2")}</p>

        <div className="content-item cb3">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h1_3")}</h3>
          <p>{t("machine_production.modal.d1_3")}</p>

        <div className="content-item cb4">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h1_4")}</h3>
          <p>{t("machine_production.modal.d1_4")}</p>
      </div>
    </div>
  );
}

export default Machine1;
