import React from 'react';
import './stylesheet/Metallurgy.css';
import img1 from '../assets/dc-electric-arc-furnace.png';
import img2 from '../assets/steel-production.png';
import { useTranslation } from "react-i18next";

function Metallurgy() {
  const { t, i18n } = useTranslation();

  return (
    <div className='metallurgy'>
      <div className="title">
        <div className="line"></div>
        <h1>{t("metallurgy.title")}</h1>
      </div>
      <div className='content-container'>
        <div className="text-container">
          <div className="background-image-container image1"></div>
          <div className="background-image-container image2"></div>
          <p>{t("metallurgy.p1")}</p>
          <p>{t("metallurgy.p2")}</p>
          <p>{t("metallurgy.p3")}</p>
          {/* <button className="request-service">{t("metallurgy.button")}</button> */}
        </div>
      </div>
    </div>
  );
}

export default Metallurgy;
