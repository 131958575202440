import React from "react";
import "./stylesheet/LoadingSpinner.css";

const LoadingSpinner = () => (
  <div className="spinner-overlay">
    <div className="spinner"></div>
  </div>
);

export default LoadingSpinner;
