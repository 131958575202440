import React from "react";
import { Link } from "react-router-dom";

import { useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import "./stylesheet/Header.css";
import titaniumLogo from "../assets/titaniumlogo.png";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import gsap from 'gsap';



function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const languageNames = {
    'en': 'English',
    'ger': 'Deutsch',
    'es': 'Español',
    'fr': 'Francés',
    'hr': 'Hrvatski',
    'hu': 'Magyar',
    'ro': 'Română',
    'sk': 'Slovenčina',
    'sn': 'Slovensky'
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setShowDropdown(!showDropdown);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);

  const toggleLanguageDropdown = (e) => {
    e.stopPropagation();
    setShowLanguageDropdown(!showLanguageDropdown);
  };

  const scrollToSection = (e, section) => {
    e.preventDefault();
    const scrollContainer = document.querySelector('.App .container');
    const panelWidth = scrollContainer.clientWidth;
    const targetPanel = document.getElementById(section);
    const targetPosition = targetPanel ? targetPanel.offsetLeft : undefined;

    if (targetPosition !== undefined) {
      gsap.to(scrollContainer, {
        duration: 1,
        scrollTo: { x: targetPosition },
        ease: 'power1.inOut',
      });
    }
  };




  return (
    <header className="header">
      <div className="logo-container">
        <img className="logo" src={titaniumLogo} alt="Titanium logo" />
        <h1 className="company-name">Titanium-Metal Kft.</h1>
      </div>
      <button className={`menu-toggle ${menuOpen ? "menu-open" : ""}`} onClick={toggleMenu}>
  {menuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
</button>

      <nav className="desktop-nav">
        <ul>
        {/* <li>
            <a href="#home" onClick={(e) => scrollToSection(e, 'home')}>
              {t("navbar.home")}
            </a>
          </li>
          <li>
            <a href="#aboutUs" onClick={(e) => scrollToSection(e, 'aboutUs')}>
              {t("navbar.about_us")}
            </a>
          </li>
          <li className="dropdown">
            <a href="#services" onClick={(e) => scrollToSection(e, 'services')}>
              {t("navbar.services")}
            </a>
            <ul className="dropdown-content">
              <li>
                <a onClick={(e) => scrollToSection(e, 'rawMaterialProduction')}>
                  {t("navbar.raw_material_production")}
                </a>
              </li>
              <li>
                <a onClick={(e) => scrollToSection(e, 'metalProcessing')}>
                  {t("navbar.metal_processing")}
                </a>
              </li>
              <li>
                <a onClick={(e) => scrollToSection(e, 'researchAndDevelopment')}>
                  {t("navbar.research_and_development")}
                </a>
              </li>
              <li>
                <a onClick={(e) => scrollToSection(e, 'machineProduction')}>
                  {t("navbar.machine_production")}
                </a>
              </li>
              <li>
                <a onClick={(e) => scrollToSection(e, 'metallurgy')}>
                  {t("navbar.metallurgy")}
                </a>
              </li>
            </ul>
          </li> */}

          {/* <li>
            <Link to="/contact-us">{t("navbar.contact_us")}</Link>
          </li> */}
        <li className="language-dropdown">
          <ul className="language-dropdown-content">
  <li
    className={i18n.language === 'en' ? 'active-language' : ''}
    onClick={() => changeLanguage("en")}
  >
    {i18n.language === 'en' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    English
  </li>
  {/* <li
    className={i18n.language === 'ger' ? 'active-language' : ''}
    onClick={() => changeLanguage("ger")}
  >
    {i18n.language === 'ger' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    Deutsch
  </li> */}
  <li
    className={i18n.language === 'es' ? 'active-language' : ''}
    onClick={() => changeLanguage("es")}
  >
    {i18n.language === 'es' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    Español
  </li>
  {/* <li
    className={i18n.language === 'fr' ? 'active-language' : ''}
    onClick={() => changeLanguage("fr")}
  >
    {i18n.language === 'fr' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    Frencés
  </li>
  <li
    className={i18n.language === 'hr' ? 'active-language' : ''}
    onClick={() => changeLanguage("hr")}
  >
    {i18n.language === 'hr' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    Hrvatski
  </li> */}
  <li
    className={i18n.language === 'hu' ? 'active-language' : ''}
    onClick={() => changeLanguage("hu")}
  >
    {i18n.language === 'hu' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    Magyar
  </li>
  {/* <li
    className={i18n.language === 'it' ? 'active-language' : ''}
    onClick={() => changeLanguage("it")}
  >
    {i18n.language === 'it' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    Italiano
  </li>
  <li
    className={i18n.language === 'ro' ? 'active-language' : ''}
    onClick={() => changeLanguage("ro")}
  >
    {i18n.language === 'ro' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    Română
  </li>
  <li
    className={i18n.language === 'sk' ? 'active-language' : ''}
    onClick={() => changeLanguage("sk")}
  >
    {i18n.language === 'sk' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    Slovenčina
  </li>
  <li
    className={i18n.language === 'sn' ? 'active-language' : ''}
    onClick={() => changeLanguage("sn")}
  >
    {i18n.language === 'sn' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    Slovensky
  </li> */}
</ul>

        </li>
        </ul>
      </nav>
      {menuOpen && (
        <div className="fullscreen-menu" onClick={toggleMenu}>
          <div className="mobile-menu-container">
            <ul>
              {/* <li>
                <Link to="/">{t("navbar.home")}</Link>
              </li> */}
              {/* <li>
                <Link to="/about-us">{t("navbar.about_us")}</Link>
              </li> */}
              {/* <li
                className={`mobile-dropdown${showDropdown ? " show" : ""}`}
                onClick={stopPropagation}
              >
                <button
                  className="services-button"
                  onClick={(e) => toggleDropdown(e)}
                >
                  {t("navbar.services")}
                </button>
                {showDropdown && (
                  <ul className="mobile-dropdown-content">
                    <li onClick={stopPropagation}>
                      <Link to="/raw-material-production">
                        {t("navbar.raw_material_production")}
                      </Link>
                    </li>
                    <li onClick={stopPropagation}>
                      <Link to="/metal-processing">
                        {t("navbar.metal_processing")}
                      </Link>
                    </li>
                    <li onClick={stopPropagation}>
                      <Link to="/research-and-development">
                        {t("navbar.research_and_development")}
                      </Link>
                    </li>
                    <li onClick={stopPropagation}>
                      <Link to="/machine-production">
                        {t("navbar.machine_production")}
                      </Link>
                    </li>
                    <li onClick={stopPropagation}>
                      <Link to="/metallurgy">{t("navbar.metallurgy")}</Link>
                    </li>
                  </ul>
                )}
              </li> */}
              {/* <li>
                <Link to="/contact-us">{t("navbar.contact_us")}</Link>
              </li> */}
              <li
    className={`mobile-language-dropdown${showLanguageDropdown ? " show" : ""}`}
    onClick={stopPropagation}
  >
    <button
  className="language-dropdown-button"
  onClick={(e) => toggleLanguageDropdown(e)}
>
  <FontAwesomeIcon icon={faGlobe} className="globe-icon" />
  {languageNames[i18n.language]}
</button>
    {showLanguageDropdown && (
      <ul className="mobile-language-dropdown-content">
        <li
    className={i18n.language === 'en' ? 'active-language' : ''}
    onClick={() => changeLanguage("en")}
  >
    {i18n.language === 'en' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    English
  </li>
  {/* <li
    className={i18n.language === 'ger' ? 'active-language' : ''}
    onClick={() => changeLanguage("ger")}
  >
    {i18n.language === 'ger' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    Deutsch
  </li> */}
  <li
    className={i18n.language === 'es' ? 'active-language' : ''}
    onClick={() => changeLanguage("es")}
  >
    {i18n.language === 'es' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    Español
  </li>
  {/* <li
    className={i18n.language === 'fr' ? 'active-language' : ''}
    onClick={() => changeLanguage("fr")}
  >
    {i18n.language === 'fr' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    Frencés
  </li>
  <li
    className={i18n.language === 'hr' ? 'active-language' : ''}
    onClick={() => changeLanguage("hr")}
  >
    {i18n.language === 'hr' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    Hrvatski
  </li> */}
  <li
    className={i18n.language === 'hu' ? 'active-language' : ''}
    onClick={() => changeLanguage("hu")}
  >
    {i18n.language === 'hu' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    Magyar
  </li>
  {/* <li
    className={i18n.language === 'ro' ? 'active-language' : ''}
    onClick={() => changeLanguage("ro")}
  >
    {i18n.language === 'ro' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    Română
  </li>
  <li
    className={i18n.language === 'sk' ? 'active-language' : ''}
    onClick={() => changeLanguage("sk")}
  >
    {i18n.language === 'sk' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    Slovenčina
  </li>
  <li
    className={i18n.language === 'sn' ? 'active-language' : ''}
    onClick={() => changeLanguage("sn")}
  >
    {i18n.language === 'sn' && <FontAwesomeIcon icon={faGlobe} className="globe-icon" />}
    Slovensky
  </li> */}
      </ul>
    )}
  </li>
            </ul>
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
