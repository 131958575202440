import React from "react";
import { useTranslation } from "react-i18next";
import banner from "../assets/rm4.jpeg";
import "./stylesheet/Machine3.css";

function Machine3() {
  const { t } = useTranslation();

  return (
    <div className="banner-container">
      <img src={banner} alt="Banner" className="banner" />
      <h1 className="banner-title">{t("machine_production.heading_3")}</h1>

      <div className="content-container">
        <div className="content-item m1">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h3_1")}</h3>
          <p>{t("machine_production.modal.d3_1")}</p>

        <div className="content-item m2">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h3_2")}</h3>
          <p>{t("machine_production.modal.d3_2")}</p>

        <div className="content-item m3">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h3_3")}</h3>
          <p>{t("machine_production.modal.d3_3")}</p>

        <div className="content-item m4">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h3_4")}</h3>
          <p>{t("machine_production.modal.d3_4")}</p>

        <div className="content-item m5">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h3_5")}</h3>
          <p>{t("machine_production.modal.d3_5")}</p>

        <div className="content-item m6">
        </div>
          <h3 className="section-text">{t("machine_production.modal.h3_6")}</h3>
          <p>{t("machine_production.modal.d3_6")}</p>
      </div>
    </div>
  );
}

export default Machine3;
