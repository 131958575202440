import React from 'react';
import './stylesheet/Home.css';
import bgVideo from '../assets/bgvideo.mp4';
import { useTranslation } from 'react-i18next';

function Home(props) {
  const { t } = useTranslation();

  return (
    <div className="home">
<video src={bgVideo} autoPlay muted loop playsInline />

      <div className='cta-container'>
        <p className='animate-character'>{t('home.cta_text')}</p>
        <button onClick={props.onButtonClick}>{t('home.cta_button')}</button>
      </div>
    </div>
  );
}

export default Home;
